import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = 'https://dealerincidentreporting.com/api/';
axios.defaults.headers.common = { Authorization: `Bearer ${store.state.token}`,
    Accept: 'application/json', 'Content-Type': 'application/json; text/plain;charset=UTF-8' };
axios.defaults.maxBodyLength = 100000000;
axios.defaults.maxContentLength = 100000000;

const Api = {
    async get(endpoint){
        try {
            const response = await axios.get(endpoint);
            return response.data;
        } catch(error){
            console.log(error);
            return error.response.data || { success: false };
        }
    },

    async post(endpoint, data){
        try {
            const response = await axios.post(endpoint, data || {});
            return response.data;
        } catch(error){
            console.log(error);
            return error.response.data || { success: false };
        }
    },

    async verify(){
        try {
            const response = await axios.get('token/verify');
            return response.data;
        } catch(error){
            console.log(error);
            return error.response.data || { success: false };
        }
    },

    async validateToken(token){
        try {
            const response = await axios.post('auth/validate', { token });
            return response.data;
        } catch(error){
            console.log(error);
            return error.response.data || { success: false };
        }
    },

    login(email, password){
        return this.post('auth/login', { email, password });
    },

    incidents: {
        async types(){
            return Api.get('incident/types');
        },

        async get(){
            return Api.get('incidents');
        },

        async view(id){
            return Api.get(`incident/${id}`);
        },

        submit(incident){
            return Api.post('incidents', incident);
        }
    }
};

export default Api;
